import React from "react";
import "../css/program.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Final from "./final";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;


export default function Program(){
    return(
        <div className="program">
            <Navbar/>
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseProgram" id="3day">
                <fieldset>
                    <legend>Choose your preferred program[s]</legend>
                
                    
                    <div className="individualProgram">
                        <div className="programBody">
                            <input type="checkbox" id="scales" name="scales" />
                            <label for="scales">3-day cleanse</label>
                        </div>
                       
                        <button>Modify</button>
                    </div>
                    <div className="individualProgram">
                        <div className="programBody">
                            <input type="checkbox" id="scales" name="scales" />
                            <label for="scales">3-day cleanse</label>
                        </div>
                       
                        <button>Modify</button>
                    </div>
                    <div className="individualProgram">
                        <div className="programBody">
                            <input type="checkbox" id="scales" name="scales" />
                            <label for="scales">3-day cleanse</label>
                        </div>
                       
                        <button>Modify</button>
                    </div>
                    <div className="individualProgram">
                        <div className="programBody">
                            <input type="checkbox" id="scales" name="scales" />
                            <label for="scales">3-day cleanse</label>
                        </div>
                       
                        <button>Modify</button>
                    </div>
                    <div className="individualProgram">
                        <div className="programBody">
                            <input type="checkbox" id="scales" name="scales" />
                            <label for="scales">3-day cleanse</label>
                        </div>
                       
                        <button>Modify</button>
                    </div>
                    <div className="programButtons">
                        <button>Clear list</button>
                        <button>Add Program</button>
                    </div>
                    
                </fieldset>
                
            </div>
            </Reveal>
            <Footer/>
            <Final/>
        </div>
    )
}