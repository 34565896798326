import React , {useState, useEffect} from "react";



export default function FPassword(){
    const {click, setClick} = useState(false);
    const {email, setEmail} = useState('');

    useEffect(()=>{

    }, [click])

    const displayMessage = (event) =>{
        event.preentDefault();

        setClick(!click);
    }
    const set_email = (event) =>{
        event.preventDefault();
        setEmail(event.target.value)
    }
    return(
        <div className="forgotpwd">
            <form>
                <fieldset>
                    <legend>Please input your email</legend>
                    <div>
                        <label>Email</label>
                        <input type="email" onChange={set_email}/>
                    </div>
                    <button onClick={displayMessage}>Submit</button>
                </fieldset>
                {}
                
            </form>
        </div>
    )
}