import React from "react";
import "../css/aboutTextField.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Final from "./final";


export default function AboutTextField(){
    return(
        <div className="aboutTextField">
            <Navbar/>
            <div className="encloseAboutTextField">
                <div className="aboutTextHeading">
                    <p>describe</p>
                    <h2 className="owner">Nyokabi</h2>
                </div>
                <textarea className="myTextField"></textarea>
            </div>
            <Footer/>
            <Final/>
        </div>
    )
}