import React from "react";


const Events = () => {
    //const p = async 
    return(
        <div>
        Hello
        </div>
    )
}

export default Events;