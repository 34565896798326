import React from "react";



export default function Call(){
    return(
        <div>
            Calendar to schedule calls.
        </div>

    )
}