import React from "react";
import "../css/texta.css";


export default function TextA(props){
    const initialArray = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
    "Let's get whole", "Transform from within, you can do this", "Nyokabi's got your back, kama kawaida", 
    "Can't wait to witness your transformative journey"]
    console.log(initialArray);
    Object.keys(initialArray).map((key, index)=>{
        console.log(key);
    })
    return(
        <div className="encloseTextA">
            <div className="encloseInputs">
            {Object.keys(initialArray).map((key, index)=>{
                return(
                    <div className="encloseIndividualInput" >
                        <input value={initialArray[key]}/>
                    </div>
                )
                
            })}
            </div>
            <div className="encloseTextInputButtons">
                <button className="close">Close</button>
                <button className="save">Update</button>
            </div>
        </div>
    )
}