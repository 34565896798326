import React from "react";
import "../css/addItem.css";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Final from "../components/final";
import { IoMdAttach } from "react-icons/io";




export default function AddItem(){
    return(
        <div className="addItemContainer">
            <Navbar/>
            <div className="encloseContainer">
                <div className="addItemHeader">
                    <p>Add Item</p>
                </div>
                <form>
                    <div className="singularItem">
                        <label>Nature</label>
                        <div className=" singularItemInput radioSection">
                            <div className="individualRadioOption">
                                <input type="radio" id="meal" name="item_category" value="meal"/>
                                <label for="meal">Meal</label>
                            </div>
                            <div className="individualRadioOption">
                               <input type="radio" id="program" name="item_category" value="program"/>
                                <label for="program">Program</label><br/>
                            </div>
                            <div className="individualRadioOption">
                                <input type="radio" id="cleanse" name="item_category" value="cleanse"/>
                                <label for="meal">Cleanse</label>
                            </div>
                            

                            
                            
                            
                        </div>
                        
                    </div>
                    <div className="singularItem c_singular">
                        <label>Item Name</label>
                        <div className="singularItemInput">
                            <input type="text"/>
                        </div>
                        
                    </div>
                    <div className="singularItem c_singular">
                        <label>Price</label>
                        <div className="singularItemInput">
                            <input type="number"/>
                        </div>
                        
                    </div>
                    <div className="singularItem c_singular">
                        <label>Image</label>
                        <div className="singularItemInput">
                            <input type="file" src="submit.gif" alt="Attach image" className="imageInput"/>
                        </div>
                        
                    </div>
                    <div className="singularItem c_singular">
                        <label>Quantity</label>
                        <div className="singularItemInput">
                            <input type="number" min="1"/>
                        </div>
                        
                    </div>
                    <div className="singularItem c_singular">
                        <label>Description</label>
                        <div className="singularItemInput">
                            <textarea></textarea>
                        </div>
                        
                    </div>
                    <div className="inputButton">
                        <button className="saveButton">Save</button>
                        <button className="postButton">Post</button>
                    </div>

                    
                </form>
            </div>
            <Footer/>
            <Final/>
        </div>
    )
}