import React, {useEffect, useState}from "react";
import "../css/cart.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Final from "./final";
import a from "../img/food5.jpg";
import mpesa from "../img/mpesa.JPG";
import airtel from "../img/airtel.JPG";
import visa from "../img/visa.JPG";
import applepay from "../img/applepay.JPG";
import mastercard from "../img/mastercard.JPG";
import stripe from "../img/stripe.JPG";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { HashLink as Link } from "react-router-hash-link";
import { CiShop } from "react-icons/ci";
import { PiShoppingCartThin } from "react-icons/pi";
import { TbShoppingCartCancel } from "react-icons/tb";
import { BsCartDash } from "react-icons/bs";


const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const headingAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export default function Cart(){
    const [token, setToken] = useState(5);
    const [empty, setEmpty] = useState(0);
    const [user, setUser] = useState("Muma")


    if (!token){
        return(
            <Link to="/signUp#loginEnclosure"/>
        ) 
    }
       
        return(
        <div className="cart">
            <Navbar/>
            <div className="encloseCart">
                <Reveal keyframes={headingAnimation}>
                    <p className="cartTitle"> {user}'s Cart</p>
                </Reveal>
                <Reveal keyframes={bottomAnimation}>
                {empty === 0 ?
                    <div className="encloseEmptyCart">
                        <BsCartDash className="emptyCartIcon"/>
                        <p className="encloseCartMessage"> There are [{empty}] items in your cart</p>
                        <button className="specialButton">
                        <CiShop/>
                        Return to shop
                        </button>
                    </div>
                     :
                <div className="encloseMyCart">
                    <div className="headerSection">
                        <p>Image</p>
                        <p>Program</p>
                        <p>Cost</p>
                        <p>Total(USD)</p>
                    </div>
                    <div className="itemSection">
                        <div className="itemImage">
                            <img src={a} alt="juiceCleanse"/>
                        </div>
                        <p>Juice cleanse</p>
                        <p>1500</p>
                        <p>1500</p>
                    </div>
                    <div className="itemSection">
                        <div className="itemImage">
                            <img src={a} alt="juiceCleanse"/>
                        </div>
                        <p>Veggie cleanse</p>
                        <p>1500</p>
                        <p>3000</p>
                    </div>
                    <p className="totalCharge">Total => USD 3000 /=</p>
                    <div className="bottomSection">
                        <div className="encloseBottomSection">
                            <button>Checkout</button>
                            <p className="email">johndoe@gmail.com</p>
                        </div>      
                    </div>
                </div>}
                </Reveal>
                <div className="paymentProgramme">
                    <div className="encloseMethod">
                        <img src={mpesa} alt="Mpesa"/>
                    </div>
                    <div className="encloseMethod">
                        <img src={airtel} alt="Airtel"/>
                    </div>
                    <div className="encloseMethod">
                        <img src={mastercard} alt="Master Card"/>
                    </div>
                    <div className="encloseMethod">
                        <img src={stripe} alt="Stripe"/>
                    </div>
                    <div className="encloseMethod">
                        <img src={airtel} alt="Airtel"/>
                    </div>
                    <div className="encloseMethod">
                    <img src={visa} alt="Visa"/>
                    </div>
                    
                    
                    
                    
                 

                </div>
            </div>
            <Footer/>
            <Final/>
        </div>
    )

}