import React, {useState, useEffect, useRef} from "react";
import a from "../img/4.jpg";
import b from "../img/hobbie1.jpg";
import c from "../img/hobbie1.jpg";
import d from "../img/hobbie2.jpg";
import e from "../img/hobbie3.jpg";
import f from "../img/hobbie4.jpg";
import g from "../img/hobbie8.jpg";
import h from "../img/hobbie6.jpg";
import i from "../img/hobbie7.jpg";
import "../css/about.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Final from "./final";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { populateHeading, populateSection, populateImage, getHeading } from "./homepage";
import { saveImage, saveImageToDB } from "./topnav";


const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;


export default function About(){
    const [pageHeader, setPageHeader] = useState();
    const [pageText, setPageText] = useState();
    const [pageSubHeading, setPageSubHeading] = useState();
    const [imageArray, setImageArray] = useState();
    const [currentHobby, setCurrentHobby] = useState();
    const [section1, setSection1] = useState();
    const [section2, setSection2] = useState();
    const [section3, setSection3] = useState();
    const [homeSectionBody, setHomeSectionBody] = useState();
    const [image, setImage] = useState({preview:'', data:''});
    const [p_image, setPImage] = useState()
    const [searchImg, setSearchImg] = useState(false);
    const [imageID, setImageID] = useState();

    let headingArray = [];
    let user, contentEditable;
    { user === "Muma" ?
        contentEditable = true :
        contentEditable = false
    }
    let headings, p, response, filename;
    const inputRef = useRef();
    const submitRef = useRef();
    let img;

    
    
    
    const weNeedHeadings = ()=>{
        headingArray.push({"id":"AB_TITLE", "body":"Get to know your coach"})
        headingArray.push({"id":"AB_HOBBY", "body":"hobbies"})
        headingArray.push({"id":"AB_COACH", "body":"Certified health and wellness + life coach.\
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor \
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."})  
    }
    //populateSection('/populateSection', 'POST', headingArray)
    async function getAllSections(){
        const alteration =  await getHeading('/getAboutSections')
        console.log(alteration)
        Object.keys(alteration).map((key)=>{
            if (alteration[key].id === 'AB_TITLE'){
                setSection1({'id':alteration[key].id, 'body':alteration[key].body})
                console.log(section1)
            }
            else if (alteration[key].id === 'AB_HOBBY'){
                setSection2({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else {
                setSection3({'id': alteration[key].id, 'body':alteration[key].body})
            }
            
        })
    }
    async function alterSection(){
        await populateSection('/alterSection', 'PUT', homeSectionBody)
    }
    function identifyPosition33(e){
        setHomeSectionBody({'id':e.target.id, 'body':e.target.innerHTML, "field":'body'}) 
    }

    function changeLogo(e){
        e.preventDefault();
        inputRef.current?.click();
        // console.log("Clicked")
    }
    async function getLogo(){
        response = await fetch('/getLogo', {
            method: 'GET',
            mode: "cors",
            redirect:"error",
            headers:{
                "Content-Type":"application/json"
            },
        })
        p = await response.json();
        if (p.status === 200){
            g = 'https://wellnesswithnyokabi.com/images/' + p.message.name;
            setImageID(p.message.id);
            setImage(g);
        }
        else{
            alert(p.message);
        }
    }
    
    
    function changeMainImage(){
        setSearchImg(true);
        inputRef.current.click();
    }
    function updateImage(e){
        e.preventDefault();
        img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        }
        setImage(img);
        submitRef.current?.click();
    }
    async function handleSubmit(e){
        e.preventDefault();
        let formData = new FormData()
        formData.append('file', img.data)
        response = await fetch('/saveImage', {
            method: 'POST',
            body: formData,
        })
        setSearchImg(false);
        p = await response.json();
        if (p.status === 200){
            saveImageToDB(p.message, imageID, "about");
        }
        else{
            alert(p.message);
        }   
    }


    
    return(
        <div>
        <Navbar/>
        <div className="about">
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseAbout" id='about_nyokabi'>
                <p id="AB_TITLE" onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true}>Get to know your coach</p>
                <div className="introSection">
                    <div className="imageSection">
                        <img src={image !== "" ? image.preview : a} alt="your-coach"/>
                        <form onSubmit={handleSubmit} style={{display:"none"}}>
                            <input ref={inputRef} type="file" onChange={updateImage} accept="png;base64,jpeg;base64,jpg;base64"/>
                            <button ref={submitRef} type='submit'>Submit</button>
                        </form>
                        {searchImg === false ? <button onClick={changeMainImage}>Change image</button> : void(0) }
                    </div>
                    <div className="firstSection">
                        <div className="encloseFirstSection">
                            <p id="AB1" id="AB_COACH" onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true}>Certified health and wellness + life coach.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <button>Alter text</button>
                        </div>
                    </div>
                    
                </div>
                
                <div className="hobbieSection">
                    <p className="heading" id="AB_HOBBY" onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true}>Hobbies</p>
                    <div className="encloseHobbies">
                        <div>
                            <img src={b} alt="Painting at home"/>
                            <p id="h1" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={c} alt="Coach playing golf"/>
                            <p id="h2" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={d} alt="Jogging in the woods"/>
                            <p id="h3" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={e} alt="Hiking"/>
                            <p id="h4" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={f} alt="Swimming"/>
                            <p id="h5" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={g} alt="Knitting"/>
                            <p id="h6" contentEditable={contentEditable} onInput={identifyPosition33}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={h} alt="Yoga"/>
                            <p id="h7" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                        <div>
                            <img src={i} alt="Baking"/>
                            <p id="h8" onInput={identifyPosition33} contentEditable={contentEditable}>Description</p>
                            <input type="checkbox"/>
                        </div>
                    </div>
                     <div className="modifyHobbies">
                             <button>Clear selection</button>
                            <button>Add hobby</button>
                        </div>
                </div>
            </div>
            </Reveal>
        </div>
        <Footer/>
        <Final/>
        </div>
    )
}