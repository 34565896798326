import React, {useState, useEffect} from "react";
import axios from 'axios';
import "../css/topnavbar.css";
import {BiSolidPhoneCall} from 'react-icons/bi';
import { NavLink } from "react-router-dom";
import {RxHamburgerMenu} from 'react-icons/rx';
import {AiOutlineShoppingCart} from "react-icons/ai";
import a from "../img/hhh.png";
import Dropdown from "./dropdown";
import TextAnime from "./textanime";
import k from "../img/wellness2.png";
import l from "../img/wellness3.png";
import TextA from "./texta";
import {v4 as uuidv4} from 'uuid';
import { useNavigate } from "react-router";
import ChangeDialog from "./changeDialog";
// import muma from "../../../images/4.jpg"
let file, p, response, data, type, img;


export const saveImage = () =>{
    let formData = new FormData();
    formData.append('image', file);
    axios.post("/saveImage", file, { 
    })
    .then((res): any => {
        console.log(res)
    }).catch((e)=>{
        console.log(e)
    });
}
export async function saveImageToDB(data, ID, section){
    response = await fetch('/updateImage', {
        method: 'PUT',
        mode: "cors",
        redirect:"error",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify(
            {
                id: ID,
                name: data,
                section: section
            }),
    })
    p = await response.json();
    if (p.status === 200){
        alert(p.message);
    }
    else{
        alert(p.message);
    }

}
function generateId(){
    return uuidv4();
}
//populate initialArray
export function populateArray(data, type){
    fetch("/insertTextSection",{
        method: 'POST',
        mode: "cors",
        redirect:"error",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify({
        "body":data,
        "type":type,
        })
    }

    )
}
    //modify item
    export async function modifyArray(items){
        response = await fetch("/alterTextSection",{
            method: 'PUT',
            mode: "cors",
            redirect:"error",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                "items":items
            })
        })
        p = await response.json();
        if (p.status === 200){
            alert(p.message);
        }
        else{
            alert(p.message)
        }
    }
    //read initialArray
    export async function getTextArray(route){
        // getTextSection
        response = await fetch(route,{
            method: 'GET',
            mode: "cors",
            redirect:"error",
            headers:{
                "Content-Type":"application/json"
            },
        }
        )
        p = await response.json();
        if (p.status === 200){
            let myTextArray = [];
            // console.log(p.message)
            // console.log(typeof(p.message))
            Object.keys(p.message).map((key)=>{
                myTextArray.push({'id': p.message[key].id, 'body':p.message[key].body})
                
            })
            return myTextArray;
        }
        else{
            return p.message;
        }
    }


export default function TopNavbar(){
    const initialArray = ["Lorem ipsum dolor sit amet", "sed do eiusmod tempor",
    "Let's get whole", "You can do this", "Nyokabi's got your back, kama kawaida", 
    "Can't wait to witness your transformative journey"]
    const navInitArray = ["About", "Coaching", "Contact", "Blog", "Shop", "Contact", "Cart", "Analytics"];
    const [navItems, setNavItems] = useState()
    const [adminNavItems, setAdminNavItems] = useState()
    const [hamburger, setHamburger] = useState();
    const [dropdown, setDropdown] = useState(false);
    const [user, setUser] = useState("Muma");
    const [textObject, setTextObject] = useState([]);
    const [textA, setTextA] = useState(initialArray);
    const [textAstatus, setTextAstatus] = useState(false);
    const [editable, setEditable] = useState(true);
    const [logo, setLogo] = useState(a);
    const [logoID, setLogoID] = useState()
    const [image, setImage] = useState({preview:'', data:''})
    const [logostate, setLogoState] = useState(false);
    const inputRef = React.useRef(null);
    const submitRef = React.useRef(null);
    const [showDialog, setShowDialog] = useState(false);
    let g, reader, imageID;

    
    useEffect(()=>{
        //getTextArray();
        //getLogo();
        //populateArray(initialArray, "navSentence");
        //populateArray(navInitArray, "navItem");
        //getTextArray();
    }, []);
    useEffect(()=>{
        console.log(image);
    },[])
    function modifyTextArray(){
        if (showDialog === true){
            setShowDialog(false);
        }
        else{
            setShowDialog(true)
        }
    }
    function saveTextArray(){
        //check if prev val === current value
        for (let i = 0; i <= Object.keys(textObject); i++){
            let items = []
            //get key
        let objKey = Object.keys(textObject).find(key => textA[key] === textObject[key.value])
            if (/\s/.test(textObject[objKey]) === true){
                if (textA[i].objKey !== textObject[i].objKey){
                    items.push([objKey, "navSentence", textObject[objKey]])
                } 
            }
            else{
                if (textA[i].objKey !== textObject[i].objKey){
                    items.push([objKey, "navItem", textObject[objKey]])
                }
            }
            modifyArray(items)
            
        }
    }



    imageID = "NV" + generateId();

    function modifyNav(e){
        e.preventDefault();   
    }
    function getAdminNavItems(){

    }
    function getNavItems(){

    }
    
   
    function addTextA(e){
        e.preventDefault();
        e.target.src = logo;
        setTextA(e.target.src);
    }

    async function getLogo(){
        response = await fetch('/getLogo', {
            method: 'GET',
            mode: "cors",
            redirect:"error",
            headers:{
                "Content-Type":"application/json"
            },
        })
        p = await response.json();
        if (p.status === 200){
            g = 'https://wellnesswithnyokabi.com/images/' + p.message.name;
            setLogoID(p.message.id);
            setLogo(g);
        }
        else{
            alert(p.message);
        }
    }
    getLogo();
    
    function updateLogo(e){
        e.preventDefault();
        img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        }
        setImage(img);
        submitRef.current?.click();
    }
    async function handleSubmit(e){
        e.preventDefault();
        let formData = new FormData()
        formData.append('file', img.data)
        response = await fetch('/saveImage', {
            method: 'POST',
            body: formData,
        })
        p = await response.json();
        if (p.status === 200){
            saveImageToDB(p.message, imageID, "logo");
        }
        else{
            alert(p.message);
        }
    }
    function changeLogo(e){
        e.preventDefault();
        inputRef.current?.click();
        // console.log("Clicked")
    }
    let generatedIdentification = "NS" + generateId();
    useEffect(()=>{
        console.log('imageChanged')
    }, [image])
    
    useEffect(()=>{
        if (user === 'Muma'){
            setEditable(true);
        }
    }, [user])
    function seeMore(){
        setDropdown(!dropdown);
    }    return(
        <div className="encloseNavbar">
        {showDialog === true ? <ChangeDialog props={textA}/> : void(0)}
        
        <div className="secondNavbar">
      
            <div className="navbar">
                <div className="encloseTop">
                    <div className="leftSide">
                        <NavLink to="/" >
                            <img src={image.preview !== "" ? image.preview: logo} alt="nyokabi-logo" className="big-logo" onClick={changeLogo}/>
                            <img src={k} alt="nyokabi_logo" className="medium-logo" onClick={changeLogo}/>
                            <img src={l} alt="nyokabi_logo" className="small-logo" onClick={changeLogo}/>
                        </NavLink>
                        <form onSubmit={handleSubmit} style={{display:"none"}}>
                            <input ref={inputRef} type="file" onChange={updateLogo} accept="png;base64,jpeg;base64,jpg;base64"/>
                            <button ref={submitRef} type='submit'>Submit</button>
                        </form>
                        
                    </div>
                    <div className="aside">
                        <TextAnime props={textA}/>
                        {showDialog === true ? void(0) : <button className="addBenefit" onClick={modifyTextArray}>Change</button>}
                        
                    </div>
                    <div className="rightSide">
                        <button className="notSmall">Clarity call</button>
                        <button className="small"><BiSolidPhoneCall/></button>
                        <button className="cartIcon"><AiOutlineShoppingCart/></button>
                        <button className="moreIcon" onClick={seeMore}><RxHamburgerMenu className="encl"/></button>
                    </div>
                </div>
               
                <div className="middleSide">
                    <ul>
                        {user === "Muma" ? 
                            <li>
                                <NavLink  to="/">Home</NavLink>
                            </li>
                            : void(0)}
                        <li>
                        <NavLink to="/about">About</NavLink></li>
                       
                        <li>
                        <NavLink to="/contact">Contact</NavLink></li>
                        <li>
                        <NavLink  to="/blog">Blog</NavLink></li>
                        <li>
                        <NavLink  to="/shop">Shop</NavLink></li>
                        <li>
                        <NavLink to="/program">Programmes</NavLink></li>
                        <li>
                        <NavLink  to="/cart">Cart</NavLink></li>
                        <div>
                            { user === "Muma" ?
                            <li>
                            <NavLink to="/">Analytics</NavLink></li>
                            : void(0)}
                        </div>
                        
                        <div>
                        </div>
                    </ul>
                </div>  
            </div>
            <div className="middleSidesixhundred">
                    <ul>
                        <li className="navbar_link">
                        <NavLink to="/about">About</NavLink></li>
                        <li>
                        <NavLink to="/">Podcast</NavLink></li>
                        <li>
                        <NavLink to="/contact">Contact</NavLink></li>
                        <li>
                        <NavLink to="/blog">Blog</NavLink></li>
                        <li>
                        <NavLink to="/shop">Shop</NavLink></li>
                        <li>
                        <NavLink to="/program">Programmes</NavLink></li>
                        
                        
                    </ul>
                    
                </div>
                {dropdown === true && <Dropdown/>}
           
        </div>
        </div>
    )
}