import React from "react";
import {BiLogoFacebook} from "react-icons/bi";
import {BiLogoTwitter} from "react-icons/bi";
import {BiLogoInstagramAlt} from "react-icons/bi";
import {BiLogoLinkedin} from "react-icons/bi";
import {BiLogoYoutube} from "react-icons/bi";
import {FaSpotify} from "react-icons/fa";
import "../css/final.css";


export default function Final(){
    return(
        <div className="finalLineFooter">
            <div className="encloseFinalFooter">
                <div className="footerLeft">
                    <span className="footerInitials">
                        <p>&copy; 2023</p>
                        <p className="footerName">Wellness with Nyokabi</p><br/>
                    </span>
                    <p className="cpyrightTag">All rights reserved</p>
                    
                </div>
            
                <div className="footerRight">
                    <div className="socials" id="socials">
                        <p className="deservesBorder">Privacy policy  </p>
                        <p className="deservesBorder">Terms and Conditions  </p>
                        <p className="deservesNoBorder">Cookie policy</p>
                    </div>
                
                </div>
            </div>
            
        </div>
    )
}