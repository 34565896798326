import React, {useEffect, useState} from "react";
import {RiArrowRightDoubleLine} from "react-icons/ri";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { populateHeading, populateSection } from "./homepage";

const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;


export default function WordSection(){
  const [text, setText] = useState();
  const [id, setID] = useState();
  const wordingRef = React.useRef(null)


  useEffect(()=>{
    populateHeading('/insertText', 'POST', 'Health is a state of complete physical, mental and social well-being, and not merely the absence of disease or infirmity. \n — World Health Organisation', 'navItem');
    //getHeading and id from context
  }, [])

  function changeContent(e){
    e.preventDefault();
    setText(wordingRef.current?.innerText)
    populateHeading('/alterText', 'PUT', id, text)
  }
  

    return(
        <div className="wordSection">
            <div class="line"></div>
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseWordSection" contentEditable="true">
                    <p useRef = {wordingRef} className="wordSectionBody" >“Health is a state of complete physical, mental and social well-being, and not merely the absence of disease or infirmity.”
                    <br/>
                    — World Health Organisation</p>          
            </div>
            <div className="encloseMyButton">
              <button className="addBenefit" onClick={changeContent}>Modify</button>
            </div>
            </Reveal>
            
       
        </div>
    )
}