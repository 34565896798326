import Recat from 'react';
import { NavLink } from "react-router-dom";
import "../css/dropdown.css";

export default function Dropdown(){
    return(
        <div className="dropdown">
            <ul>
                <li>
                <NavLink to="/about">About</NavLink></li>
                <li>
                <NavLink to="/Coaching">Coaching</NavLink>
                </li>
                <li>
                <NavLink to="/">Podcast</NavLink></li>
                <li>
                <NavLink to="/contact">Contact</NavLink></li>
                <li>
                <NavLink to="/blog">Blog</NavLink></li>
                <li>
                <NavLink to="/shop">Shop</NavLink></li>
                <li>
                <NavLink to="/program">Programmes</NavLink></li>
            </ul>                   
        </div>
    )
}