import React from "react";
import "../css/changeDialog.css";



const ChangeDialog = (data) =>{
    return(
        <div className="changeDialog">
            <div className="changeDialogContent">
                <ul className="script" contentEditable="true">
                    {Object.keys(data.props).map((key)=>{
                    return(
                        <li>{data.props[key]}</li>
                    )
                    })}
                </ul>
                <div className="submissionSection">
                    <button className="cancel addBenefit">Cancel</button>
                    <button className="save addBenefit">Save</button>
                </div>
            </div>
            
        </div>
    )
}
export default ChangeDialog;