import React, {useState, useEffect} from "react";
import "../css/login.css";
import Final from "./final";
import Footer from "./footer";
import Navbar from "./navbar";
import a from "../img/hhh.png";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import axios from "axios";

const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;



export default function Register(){
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [ip, setIp] = useState("");
    
    useEffect(()=>{
        getIp();
    },[email])
    const getEmail = (e) =>{
        e.preventDefault();
        setEmail(e.target.value);
    }
    const getPassword = (event) =>{
        event.preventDefault();
        setPwd(event.target.value);
    }
    const getIp = async () =>{
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIp(res.data.ip);
    }
    
    async function sajili(event){
        event.preventDefault();
        if (email !== "" && pwd !== ""){
            await fetch('/register', {
                method: 'POST',
                mode: "cors",
                redirect:"error",
                headers:{
                    "Content-Type":"application/json"
                },
                body: JSON.stringify({
                    "email": email, 
                    "password": pwd, 
                    "subscribed": false,
                    "date_joined": new Date().toISOString().slice(0, 19).replace('T', ' '),
                    "last_login": new Date().toISOString().slice(0, 19).replace('T', ' '),
                    "ip": ip,
                })
                           
            }).then(async response => response.json())
            .then(async response => {
                const p = await response;
                if (p.status === 200){
                    alert(p.message);
                }
                else{
                    alert(p.message);
                }
            });
        }        
    }
    return(
        <div className="login">
            <Navbar/>
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseLogin" tabindex="1" >
                <div className="encloseImage2">
                    <img src={a} alt="logo"/>
                </div>

                <div className="loginContents loginContents2">
                    <p>Sign Up</p>
                    <form>
                        <div className="individualItem">
                            <label>Email</label><br/>
                            <input type="email" onChange={getEmail}/>
                        </div>
                        <div className="individualItem">
                            <label>Password</label><br/>
                            <input type="password" onChange={getPassword}/>
                        </div>
                        <div className="individualItemButton">
                            <button onClick={sajili}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            </Reveal>
            <Footer/>
            <Final/>
        </div>
    )
}