import React from "react";
import "../css/contact.css";
import Navbar from "./navbar";
import Final from "./final";
import Footer from "./footer";
import {FcContacts} from "react-icons/fc";
import { SlNote } from "react-icons/sl";
import { HiPencil } from "react-icons/hi2";
import { PiNotePencilFill } from "react-icons/pi";
import { SlEnvolopeLetter } from "react-icons/sl";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;



export default function Contact(){
    function sendInquiry(){
        void(0);
    }
    return(
        <div className="contact">
            <Navbar/>
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseContact">
                <div className="contactIcon">
                    <SlEnvolopeLetter className="contactSth"/>
                </div>
                
                <div className="contactForm">
                    <p className="headerT">To submit an inquiry, please fill out the form below.</p>
                    <form>
                        <div className="formInput">
                            <label>Name</label><br/>
                            <input type="text"/>
                        </div>
                        <div className="formInput">
                            <label>Email</label><br/>
                            <input type="text"/>
                        </div>
                        <div className="formInput">
                            <label>Subject</label><br/>
                            <input type="text"/>
                        </div>
                        <div className="formInput">
                            <label>Message</label><br/>
                            <textarea className="message"></textarea>
                        </div>
                        <div className="formButton">
                            <input type="submit"/>
                        </div>
                    </form>
                </div>
            </div>
            </Reveal>
            <Footer/>
            <Final/>
        </div>
    )
}