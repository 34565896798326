import React from "react";



export default function Meal(){
    return(
        <div className="meal">
            <div className="image">
            </div>
            <div className="text">
            </div>
        </div>
    )
}