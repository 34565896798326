
import { BrowserRouter } from 'react-router-dom';
import './App.css';
// import Navbar from "../src/components/navbar";
import About from './components/about';
import Contact from './components/contact';
import Program from './components/program';
import Cart from './components/cart';
import Login from './components/login';
import Register from './components/register';
import Blog from "./components/blog";
import Shop from './components/shop';
import Homepage from './components/homepage';
import Coaching from './components/coaching';
import { Route , Routes, Navigate} from 'react-router-dom';
import FPassword from './components/fpassword';
import Mainblog from './components/mainblog';
import Reviews from './components/reviews';
import Meal from "./components/meal";
import Events from './components/events';
import Call from './components/call';
import BlogScript from './components/blogForm';
import AboutTextField from './components/aboutTextField';
import AddItem from './components/addItem';
import TextA from './components/texta';
import ChangeDialog from './components/changeDialog';


function App() {
  let data, currentMeal;
  data = 'hello';
  currentMeal = 'hello';

  return (
    <div className="App" >
      <Routes>
        <Route path='/' element={<Homepage/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/program' element={<Program/>}/>
        <Route path='/coaching' element={<Coaching/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/blog' element={<Mainblog/>}/>
        <Route path='/article' element={<Blog props={data}/>}/>
        <Route path='/reviews' element={<Reviews/>}/>
        <Route path='/shop' element={<Shop/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/meal' element={<Meal props={currentMeal}/>}/>
        <Route path='/forgotpassword' element={<FPassword/>}/>
        <Route path='/events' element={<Events/>}/>
        <Route path='/calls' element={<Call/>}/>
        <Route path='/writeBlog' element={<BlogScript/>}/>
        <Route path="/sss" element={<AboutTextField/>}/>
        <Route path="/ppp" element={<AddItem/>}/>
        <Route path='/changeTxt' element={<TextA/>}/>
        <Route path='/modifyText' element={<ChangeDialog/>}/>
        <Route path='*' element={<Homepage/>} />

      </Routes>
    </div>
  );
}

export default App;
