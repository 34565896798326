import React, {useState, useEffect} from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import "../css/blogForm.css";
import axios from "axios";


export default function BlogScript(){
    const [title, setTitle] = useState();
    const [script, setScript] = useState();
    const [action, setAction] = useState("save");
    const time = setInterval(sendScript, 7200);
    const [ip,setIp] = useState("");
    const user = "Mulika";
    const [blogID, setBlogID] = useState();
    let currentBlog, currentTitle;

    const getIp = async () =>{
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIp(res.data.ip);
    }
    const saveTitle = (e)=>{
        e.preventDefault();
        setTitle(e.target.value);
    }
    const sendScript = () =>{
        fetch('/sendBlog', {
            method: 'POST',
            mode: "cors",
            redirect:"error",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                "key": user,
                "title": title,
                "script": script,
                "action":action,
                "time": new Date().toISOString().slice(0, 19).replace('T', ' '),
                "ip": ip,
            }),
        }).then(async response => response.json())
        .then(async response => {
            const p = await response;
            if (p.status === 200){
                alert(p.message);
            }
            else{
                alert(p.message);
            }
        });
    }
    const saveBlog = (e) =>{
        e.preventDefault();
        setAction("save");
        sendScript();
    }
    const postBlog = (e) =>{
        e.preventDefault();
        setAction("post");
        sendScript();
    }
    const saveScript = (e)=>{
        e.preventDefault();
        setScript(e.target.value);
    }
    useEffect(()=>{
        getIp();
    }, [ip])

    
    return(
        <div className="scriptEnclosure">
            <Navbar/>
            <div>
                {blogID ? 
                    <div className="encloseScriptContent" >
                        <div className="encloseBlogHeader">
                            <form>
                                <label>Title</label>
                                <input value={currentTitle} onChange={saveTitle}/>
                            </form>
                        </div>
                        <div className="encloseBlogScript">
                            <textarea value={currentBlog} onChange={saveScript} className="script" placeholder="Lets's write.................">
                            
                            </textarea>
                        </div>
                        <div className="encloseSubmitButton">
                            <button onClick={postBlog} className="enclosePost">post</button>
                        </div>
                    </div>
                    :
                    <div className="encloseScriptContent" >
                        <div className="encloseBlogHeader">
                        <form>
                            <label>Title</label>
                            <input onChange={saveTitle}/>
                        </form>
                        </div>
                        <div className="encloseBlogScript">
                            <textarea onChange={saveScript} className="script" placeholder="Lets's write.................">
                            
                            </textarea>
                        </div>
                        <div className="encloseSubmitButton">
                            <button onClick={saveBlog} className="encloseSave">save</button>
                            <button onClick={postBlog} className="enclosePost">post</button>
                        </div>
                    </div>
                }
                
            </div>
            <Footer/>

           
        </div>
    )
}