import React, {useState} from "react";
import "../css/homepage.css";
import { Reveal, Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Slide, Zoom} from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import CleanseList from "./cleanseList";
import { HashLink as Link } from 'react-router-hash-link';

const headingAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const rightAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const leftAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;


export default function Cleanse(){
  //append, modify and delete
  const initialList = [
  {
    id: 'a',
    task: 'Boosted circulation',
  },
  {
    id: 'b',
    task: 'Better sleep',
  },
  {
    id: 'c',
    task: 'Weight loss',
  },
  {
    id: 'd',
    task: 'Mind clarity',
  },
  {
    id: 'e',
    task: 'Reduced inflammation',
  },
  {
    id: 'f',
    task: 'Energy Boost',
  },
  ]
  let response, p;
  const [id, setID] = useState();
  const [body, setBody] = useState();
  const [m_list, setMList] = useState(initialList);
  const populateListing = () => {
    response = fetch('/insertListing', {
      method:'POST',
      mode:'cors',
      redirect:'error',
      headers:{
        "Content-Type":"application/json"
      },
      body: JSON.stringify({
        "body":body,
      })
    })
  }
  const alterListing = () =>{
    response = fetch('/alterListing', {
      method:'PUT',
      mode:"cors",
      redirect:"error",
      headers:{
        "Content-Type":"application/json"
      },
      body: JSON.stringify({
        id:id,
        body:body
      })
    })
  }
  const deleteListing = () =>{
    response = fetch('/deleteListing', {
      method:'DELETE',
      mode:"cors",
      redirect:"error",
      headers:{
        "Content-Type":"application/json"
      },
      body: JSON.stringify({
        id:id
      })
    })
  }
  const getListing = () => {
    response = fetch('/getListing', {
      method:'GET',
      mode:"cors",
      redirect:"error",
      headers:{
        "Content-Type":"application/json"
      }
    })

  }
  
  
  m_list.map((key, index)=>{
    console.log(m_list[index].task);
    console.log("hello")
  })
    return(
        <div className="cleanse">
            <div className="encloseCleanseContent">
                <Reveal keyframes={headingAnimation}>
                <p className="heading">Why Cleanse?</p></Reveal>
                <div className="belowHeading"></div>
                <div className="cleanseContent">
                    <div className="encloseIframeContent">
                      <iframe width="400" height="400" src="https://www.youtube.com/embed/2NR4_5dt7JA?si=I-yiceLhh61Tr33K&amp;start=27" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      <Link to="" className="addBenefit">Upload</Link>
                    </div>
                    
                    <Reveal className="encloseIframeBenefits" keyframes={rightAnimation}>
                        <div className="encloseList">
                            <ul>
                            {<CleanseList props={initialList}/>}
                            </ul>
                        </div>
                    </Reveal>
                   
                </div>
                <div className="encloseCleanseButtons">
                  <Link className="addBenefit"> Add benefit</Link>
                </div>
                
            </div>
        </div>
    )
}