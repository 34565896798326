import React, {useState, useEffect} from "react";
import "../css/homepage.css";
import j from "../img/food7.jpg";
// import b from "../img/9.jpg";
import n from "../img/meditate.jpg";
import o from "../img/coachh.png";
import p from "../img/visionboard.jpg";
import q from "../img/aa.png";
import {BsArrowLeftShort} from "react-icons/bs";
import {BsArrowRightShort} from "react-icons/bs";
import a from "../img/9.jpg";
import c from "../img/food2.jpg";
import Reviews from "./reviews";
// import d from "../img/who.jpg";
import e from "../img/11.jpg";
import { Wave } from 'react-animated-text';
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router";
import { HashLink as Link } from 'react-router-hash-link';
import { populateHeading, populateSection, populateImage, getHeading } from "./homepage";
import { populateArray, getTextArray, modifyArray } from "./topnav";

const headingAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const rightAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const leftAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;



export default function Meals(){
    const [contentEditable, setContentEditable] = useState(false);
    const navigate = useNavigate();
    const [rClick, setRClick] = useState(0);
    const [add, setAdd] = useState(false);
    const [reduce, setReduce] = useState(false);
    const [presentId, setPresentId] = useState(false);
    const [wordSection1, setWordSection1] = useState({'id':"",'body':""});
    const [wordSection2, setWordSection2] = useState({'id':"",'body':""});
    const [wordSection3, setWordSection3] = useState({'id':"",'body':""});
    const [wordSection4, setWordSection4] = useState({'id':"",'body':""});
    const [wordSection5, setWordSection5] = useState({'id':"", 'body':""});
    const [heading1, setHeading1] = useState({'id':"", 'body':""});
    const [heading2, setHeading2] = useState({'id':"", 'body':""});
    const [heading3, setHeading3] = useState({'id':"", 'body':""});
    const [heading4, setHeading4] = useState({'id':"", 'body':""});
    const [heading5, setHeading5] = useState({'id':"", 'body':""});
    const [heading6, setHeading6] = useState({'id':"", 'body':""});
    const [heading7, setHeading7] = useState({'id':"", 'body':""});
    const [heading8, setHeading8] = useState({'id':"", 'body':""});
    const [heading9, setHeading9] = useState({'id':"", 'body':""});
    const [section1, setSection1] = useState({'id':"", "body":""});
    const [section2, setSection2] = useState({'id':"", "body":""});
    const [section22, setSection22] = useState({'id':"", "body":""});
    const [section3, setSection3] = useState({'id':"", "body":""});
    const [section4, setSection4] = useState({'id':"", "body":""});
    const [section44, setSection44] = useState({'id':"", "body":""});
    const [section5, setSection5] = useState({'id':"", "body":""});
    const [section55, setSection55] = useState({'id':"", "body":""});
    const [section555, setSection555] = useState({'id':"", "body":""});
    const [section6, setSection6] = useState({'id':"", "body":""});
    const [section7, setSection7] = useState({'id':"", "body":""});
    const [section77, setSection77] = useState({'id':"", "body":""});
    const [section777, setSection777] = useState({'id':"", "body":""});
    const [section7777, setSection7777] = useState({'id':"", "body":""});
    const [textA, setTextA] = useState([]);
    const [addEligibility, setAddEligibility] = useState(false);
    const [editReviewHeader,setEditReviewHeader] = useState(false)
   
    const [changeCoachSection, setChangeCoachSection] = useState(false);
    const [eligibility, setEligibility] = useState([]);
    const [section, setSection] = useState(0);
    const [homeSection,setHomeSection] = useState();
    const [homeSectionHeading, setHomeSectionHeading] = useState({'id':"", 'body':""});
    const [homeSectionBody, setHomeSectionBody] = useState({'id':"", 'body':""});
    const [listBody, setListBody] = useState({'id':"", 'body':""})
    let eligibilityLength;
    const textArray = [];
    const headingArray = [];
    const sectionArray = [];

    const wordRef1 = React.useRef(null);
    const wordRef2 = React.useRef(null);
    const wordRef3 = React.useRef(null);
    const wordRef4 = React.useRef(null);
    const wordRef5 = React.useRef(null);
    const twiceEncloseRef = React.useRef();
    const mealSectionRef = React.useRef(null);
    const mindRef = React.useRef(null);
    const mindSubRef = React.useRef(null);
    const mindBodyRef = React.useRef(null);
    const rightHelpRef = React.useRef(null);
    const rightHelpClarityRef = React.useRef(null);
    const mentorRef = React.useRef(null);
    const mentorBodyRef = React.useRef(null);
    const BlogRef = React.useRef(null);
    const leftElaborationBodyRef = React.useRef();
    const leftElaborationRef = React.useRef();
    const rightElaborationRef = React.useRef();
    const rightElaborationBodyRef = React.useRef();
    const mealSectionBodyRef = React.useRef();
    const eventsRef = React.useRef();
    const reviewRef = React.useRef();
    const cautionRef = React.useRef();
    const twiceEncloseDurationGroupRef = React.useRef();
    const twiceEncloseDurationRef = React.useRef()
    const twiceEncloseDurationGroup1Ref = React.useRef();
    const twiceEncloseDuration1Ref = React.useRef();
    const [changeCleanseDuration, setChangedCleanseDuration] = useState(true);
    const [helpText, setHelpText] = useState("click here to request a call");
    const [eventText, setEventText] = useState('Mark your calendar');
    const [target, setTarget] = useState();
    const newReviewRef = React.useRef();
    let response, mentor, route, method, type, _name;
    function populateCurrentWordSection(){
        textArray.push({'id':'WD1','body':'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut \
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris \
                        nisi ut aliquip ex ea commodo consequat.', 'type':'wordingSection'})
        textArray.push({'id':'WD2','body':'Bid adieu to unexplained fatigue. I can help you rekindle the spark that exists within by leveraging nutrition. Find clarity and\
            purpose by harnessing what nature offers. Live a life that feels like yours','type':'wordingSection'})
        textArray.push({'id':'WD3','body':'Grab your groove by the horns. I did it, so can you.','type':'wordingSection'})
        textArray.push({'id':'WD4','body':'Rejuvenate your body by harnessing the benefits of my proven and tested cleanse. Your body will thank you afterwards.','type':'wordingSection'})
        textArray.push({'id':'WD5','body':'Rejuvenate your body by harnessing the benefits of my proven and tested cleanse. Your body will thank you afterwards.','type':'wordingSection'})
    }

    //initializing functions

    // populateCurrentWordSection();
    // populateArray(textArray, 'wordingSection');
    // weNeedHeadings();
    // populateHeading('/populateTitles', 'POST',headingArray)
    // weNeedSections();
    // populateSection('/populateSection', 'POST', sectionArray);
    //populateSection('/insertListing', 'POST', eligible)
    
    async function getWordingArray(){
        const alteration =  await getTextArray('/getWordingSection')
        Object.keys(alteration).map((key)=>{
            if (alteration[key].id === 'WD1'){
                setWordSection1({'id':alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'WD2'){
                setWordSection2({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'WD3'){
                setWordSection3({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'WD4'){
                setWordSection4({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else {
                setWordSection5({'id': alteration[key].id, 'body':alteration[key].body})
            }
        })
    }
    function changeSection1(e){
        e.preventDefault();
        setSection(1);
    }
    function changeSection2(e){
        e.preventDefault()
        setSection(2);
    }
    function changeSection3(e){
        e.preventDefault();
        setSection(3);
    }
    function changeSection4(e){
        e.preventDefault();
        setSection(4);
    }
    function changeSection5(e){
        e.preventDefault();
        setSection(5);
    }
    function handleBlur(e){
        changeWordSection(e.target.innerHTML)
    }
    function changeWordSection(item){
        if (section === 1 && (wordSection1.body !== item)){
            p = {'id':"WD1",'body':item};   
        }
        else if (section === 2 && (wordSection1.body !== item)){
            p = {'id':"WD2",'body':item};
        }
        else if (section === 3 && (wordSection1.body !== item)){
            p = {'id':"WD3",'body':item};
        }
        else if (section === 4 && (wordSection1.body !== item)){
            p = {'id':"WD4",'body':item};
        }
        else {
            p = {'id':"WD2",'body':item};
        }
        if (p.body !== ""){
            modifyArray(p);
        }
       
    }
    function weNeedHeadings(){
        headingArray.push({'id':"MH1",'name':"So what does the cleanse entail?"})
        headingArray.push({'id':"MH2",'name':"Can i take the cleanse?"})
        headingArray.push({'id':"MH3",'name':"Just a cleanse away"})
        headingArray.push({'id':"MH4", 'name':"Recipe for the week"})
        headingArray.push({'id':"MH5", 'name':"Healthy Mind, healthy body"})
        headingArray.push({'id':"MH6", 'name':"What are people saying"})
        headingArray.push({'id':"MH7", 'name':"Meet the coach"})
        headingArray.push({'id':"MH8", 'name':"Mark your calendar"})
        headingArray.push({'id':"MH9", 'name':"Nutrient of the week"})
    }
    
    async function getAllHeadings(){
        let alteration =  await getHeading('/getTitles')
        Object.keys(alteration).map((key)=>{
            if (alteration[key].id === 'MH1'){
                setHeading1({'id':alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH2'){
                setHeading2({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH3'){
                setHeading3({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH4'){
                setHeading4({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH5'){
                setHeading5({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH6'){
                setHeading6({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH7'){
                setHeading7({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MH8'){
                setHeading8({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else {
                setHeading9({'id': alteration[key].id, 'body':alteration[key].body})
            }
        })
    }
    function weNeedSections(){
        sectionArray.push({'id':"MB1",'body':"I will guide you as you ................ Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris\
            nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris \
            nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut \
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris \
            nisi ut aliquip ex ea commodo consequat."})
        sectionArray.push({'id':"MB2", 'body':"The cleanse is suitable for people who are:"})
        sectionArray.push({'id':"MB22", 'body':" If you have any medical condition, please consult your physician prior to \
            attempting the cleanse."})
        sectionArray.push({'id':"MB3", 'body':"Some catchy relatable phrase. Worked late? Need something not too challenging? \
            Our choice of midweek meal recipes is what we like to cook on busy weekdays."})
        sectionArray.push({'id':"MB4", 'body':"Power of stillness"})
        sectionArray.push({'id':"MB44", 'body':"In a world filled with busy schedules, stressful situations,\
            joyful moments and tiresome habits, taking a few minutes off to relax\
            and calm the mind. Sign up to join the meditation class."})
        sectionArray.push({'id':"MB5", 'body':"You came seeking assistance because you know it is\
            time to change. Let's do this mate"})
        sectionArray.push({'id':"MB55", 'body':"Schedule a clarity call"})
        sectionArray.push({'id':"MB555", 'body':"On this call, I will lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut \
            labore et dolore magna aliqua"})
        sectionArray.push({'id': "MB6", 'body':"Hello, I am Nyokabi Njoroge, a certified health and wellness coach.\
            Guiding people to look deeper and find purpose in life while cultivating \
            a healthy relationship with diet is what I do best. I am looking forward\
            to helping you find your intrinsic self. Life is too short to live an average life."})
        sectionArray.push({'id':"MB7", "body":"14"})
        sectionArray.push({'id':"MB77", "body":"day cleanse"})
        sectionArray.push({'id':"MB777", "body":"21"})
        sectionArray.push({'id':"MB7777", "body":"day cleanse"})
    }
   
    //get all sections
    async function getAllSections(){
        const alteration =  await getHeading('/getSections')
        console.log(alteration)
        Object.keys(alteration).map((key)=>{
            if (alteration[key].id === 'MB1'){
                setSection1({'id':alteration[key].id, 'body':alteration[key].body})
                console.log(section1)
            }
            else if (alteration[key].id === 'MB2'){
                setSection2({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB22'){
                setSection22({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB3'){
                setSection3({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB4'){
                setSection4({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB44'){
                setSection44({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB5'){
                setSection5({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB55'){
                setSection55({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB555'){
                setSection555({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB6'){
                setSection6({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB7'){
                setSection7({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB77'){
                setSection77({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else if (alteration[key].id === 'MB777'){
                setSection777({'id': alteration[key].id, 'body':alteration[key].body})
            }
            else{
                setSection7777({'id': alteration[key].id, 'body':alteration[key].body})
            }
        })
    }
    //change Section
    async function alterSection(){
        await populateSection('/alterSection', 'PUT', homeSectionBody)
    }
    function discardEditReview(){
        setEditReviewHeader(false);
    }
    function invokeEditReview(){
        setEditReviewHeader(true);
    }
    function coachSectionModification(){
        setChangeCoachSection(true);
    }
    //getLists
    const whoIsEligible = async ()=>{
        let h = []
        const alteration =  await getHeading('/getListing')
        Object.keys(alteration).map((key)=>{
            h.push({"id":alteration[key].id, "body":alteration[key].body})
        })
        setEligibility(h);
        eligibilityLength = Object.keys(alteration).length;
        console.log(eligibilityLength)
    }
    
    //addToList
    const addToEligibilityList = async ()=>{
        const alteration =  await populateSection('/insertListing', 'POST', listBody)
        setEligibility(alteration);
        eligibilityLength++
        
    }
    const newItem = (e) =>{
        let f_no = Math.random(100)
        let f_ID = 'elig' + f_no
        setListBody({"id":f_ID, "body":e.target.innerHTML})
    }
    const showMe = (e) =>{
        setListBody({"id":e.target.id, "body":e.target.innerHTML})
    }
    //removeFrom List
    const deleteFromList = async (e)=>{
        const alteration = await populateSection('/deleteListing', 'DELETE', e.target.id);
        eligibilityLength--
    }
    //alterList
    const alterList = async ()=>{
        const alteration = await populateSection('/alterListing', 'PUT', listBody)
    }
    const discardItem = ()=>{
        setAddEligibility(false);
    }
    const addEligibleRequirement = ()=>{
        setAddEligibility(true);
    }

   

    //images 
    //populate images
    //get images
    //alter images
    function modifySection(){
        void(0)
    }
    function identifyPosition1(){
        setHomeSection(1);
        setHomeSectionHeading({'id':leftElaborationRef.current.id,'body':leftElaborationRef.current.textContent})
    }
 
    function identifyPosition2(){
        setHomeSection(2);
        setHomeSectionHeading({'id':rightElaborationRef.current.id,'body':rightElaborationRef.current.textContent})
    }
    function identifyPosition3(){
        setHomeSection(3);
        setHomeSectionHeading({'id':twiceEncloseRef.current?.id,'body':twiceEncloseRef.current?.textContent})
    }

    function identifyPosition4(){
        setHomeSection(4);
        setHomeSectionHeading({'id':mealSectionRef.current.id,'body':mealSectionRef.current.textContent})
    }
    function identifyPosition5(){
        setHomeSection(5);
        setHomeSectionHeading({'id':mindRef.current.id,'body':mindRef.current.textContent})
    }
    function identifyPosition6(e){
        setHomeSection(6);
        setHomeSectionHeading({'id':newReviewRef.current.id,'body':newReviewRef.current.textContent})
    }
    function identifyPosition7(){
        setHomeSection(7);
        setHomeSectionHeading({'id':mentorRef.current.id,'body':mentorRef.current.textContent})
    }
    function identifyPosition8(){
        setHomeSection(8);
        setHomeSectionHeading({'id':eventsRef.current.id,'body':eventsRef.current.textContent})

    }
    function identifyPosition9(){
        setHomeSection(9);
        setHomeSectionHeading({'id':BlogRef.current.id,'body':BlogRef.current.textContent})
    }
    function identifyPosition22(){
        setHomeSection(22);
    }
    function identifyPosition33(e){
        setHomeSectionBody({'id':e.target.id, 'body':e.target.innerHTML, "field":'body'}) 
    }
    function fillHomeBody(){
        populateHeading('/alterTitle', 'PUT', homeSectionHeading)
    } 
    useEffect(()=>{
        getWordingArray();
        getAllHeadings();
        getAllSections();
        whoIsEligible();
        
    }, []);

    // useEffect(()=>{
    //    console.log(leftElaborationRef.current.textContent) 
    // }, [section])
    // useEffect(()=>{
    //     populateCurrentSection();
    //     populateHeading(route, method, _name, type)
    //     //populateContext

    // },[])
    // useEffect(()=>{
    //     changeWordSection();
    // }, [wordSection1, wordSection2, wordSection3, wordSection4])
    function addClick(){
        void(0)
    }
    function reduceClick(){
        void(0)
    }


    function showMeal(event){
        event.preventDefault();
        navigate('/meal');    
    }
    function headToEvents(event){
        event.preventDefault();
        navigate('/events')
    }
   
    function goToPrograms(e){
        e.preventDefault();
        navigate('/program#3day');
    }
    function updateBlogLink(e){
        e.preventDefault();
        setPresentId(!presentId);
    }
    const headingStyle={
        fontSize: '2em',
        textTransform: 'uppercase',
        fontFamily: 'Roboto Condensed',
        fontWeight: '600'
    }
    return(
        <div className="meals">
            <div className="wordSection">
                <div className="line"></div>
                <div className="encloseWordSection">
                    <Reveal keyframes={rightAnimation}>
                        <p ref={wordRef1} id={wordSection1.id} onInput={changeSection1} onBlur={handleBlur} className="wordSectionBody" contentEditable={contentEditable} suppressContentEditableWarning={true} >
                        {wordSection1.body}
                        </p>
                    </Reveal>
                </div>
            </div>
            <div className="elaboration" >
                <div className="encloseElaboration">
                    <Reveal className="leftElaboration" keyframes={rightAnimation}>
                    <div>
                        <div className="encloseLeftElaboration" >
                            <p ref={leftElaborationRef} id="MH1" contentEditable={contentEditable} suppressContentEditableWarning={true} onInput={identifyPosition1} className="encloseHeading">{heading1.body}</p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                            </div>
                            <p ref={leftElaborationBodyRef} onInput={identifyPosition1} contentEditable={contentEditable} suppressContentEditableWarning={true} className="body">{section1.body}</p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={alterSection}>Change Body</button>
                            </div>
                        </div>
                       
                    </div>
                    </Reveal>
                    <Reveal className="rightElaboration" keyframes={leftAnimation}>
                    <div>
                        <div className="encloseRightElaboration" >
                            <p  ref={rightElaborationRef} onInput={identifyPosition2} id="MH2" className="encloseHeading" contentEditable={contentEditable} suppressContentEditableWarning={true} >{heading2.body}</p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={fillHomeBody}>Change Title</button>
                            </div>
                            <div className="body">
                                <p ref={rightElaborationBodyRef} onInput={identifyPosition2} contentEditable={contentEditable} suppressContentEditableWarning={true}>{section2.body}</p>
                                <div className="encloseMyButton">
                                    <button className="addBenefit" onClick={alterSection}>Modify</button>
                                </div>
                            <ul>
                                {Object.keys(eligibility).map((key)=>{
                                    return(
                                        <div>
                                            <li contentEditable={contentEditable} suppressContentEditableWarning={true} id={eligibility[key].id} onInput={showMe}>{eligibility[key].body}</li>
                                            <div className="changeButtons">
                                                <button id={eligibility[key].id} onClick={deleteFromList}>Delete</button>
                                                <button id={eligibility[key].id} onClick={alterList}>Modify</button>
                                            </div>
                                        </div>
                                        
                                        
                                    )
                                })}
                                {addEligibility === true ?
                                    <div>
                                            <li contentEditable={contentEditable} suppressContentEditableWarning={true} onInput={newItem}></li>
                                            <div className="changeButtons">
                                                <button onClick={discardItem}>Discard</button>
                                                <button  onClick={addToEligibilityList}>Save</button>
                                            </div>
                                    </div>
                                    :
                                    <div className="encloseMyButton">
                                    <button className="addBenefit" onClick={addEligibleRequirement}>Add to list</button>
                                </div>
                                }

                                
                            </ul>
                            <p ref={cautionRef} id="caution" contentEditable = {contentEditable} suppressContentEditableWarning={true} onClick={identifyPosition22}>
                            {section22.body}
                            </p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={alterSection}>Modify</button>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                    </Reveal>
                
                </div>
            </div>
            <div className="twiceencloseCleanse" >
                <div className="encloseCleanse">
                    <Reveal keyframes={headingAnimation}>
                        <div>
                            <p ref={twiceEncloseRef} id="MH3" onInput={identifyPosition3} contentEditable={contentEditable} suppressContentEditableWarning={true} className="heading">{heading3.body}</p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                            </div>
                        </div>
                    </Reveal>
                    <div className="belowHeading"></div>
                    <Reveal keyframes={bottomAnimation}>
                    <div className="encloseMyCleanses">
                        <div className="fourteendayCleanse" onClick={changeCleanseDuration === true ? void(0) : goToPrograms}>
                            <div className="cleanseIcon">
                                <p >🍹</p>
                            </div>
                            <div className="enclose14Cleanse" style={{backgroundImage : `url("${a}")`}}>
                                {changeCleanseDuration === true ?
                                    <div className="enclose14CleanseContents">
                                        <p onInput={identifyPosition33} id="MB7" contentEditable={contentEditable} suppressContentEditableWarning={true} className="duration" >{section7.body}</p>
                                        <div className="encloseMyButton">
                                            <button className="addBenefit" onClick={alterSection}>Change Body</button>
                                        </div>
                                        <p onInput={identifyPosition33} id="MB77" contentEditable={contentEditable} suppressContentEditableWarning={true} className="someWording" >{section77.body}</p>
                                        <div className="encloseMyButton">
                                            <button className="addBenefit" onClick={alterSection}>Change Body</button>
                                        </div>
                                    </div>:
                                    <Link to="/program#3day" className="enclose14CleanseContents">    
                                        <p ref={twiceEncloseDurationRef} onInput={identifyPosition33} id="MB777" className="duration" >{section7.body}</p>
                                        <p ref={twiceEncloseDurationGroupRef} onInput={identifyPosition33} id="MB7777" className="someWording" >{section77.body}</p>
                                    </Link>
                                }
                                
                            </div>
                        </div>
                        <div className="twentyonedayCleanse" onClick={changeCleanseDuration === true ? void(0) : goToPrograms}>
                            <div className="cleanseIcon">
                                <p >🍹</p>
                            </div>
                            <div className="enclose14Cleanse" style={{backgroundImage : `url("${c}")`}}>
                            {changeCleanseDuration === true ?
                                <div className="enclose14CleanseContents">
                                    <p onInput={identifyPosition33} id="MB777" contentEditable={contentEditable} suppressContentEditableWarning={true} className="duration" >{section777.body}</p>
                                    <div className="encloseMyButton">
                                        <button className="addBenefit" onClick={alterSection}>Change Body</button>
                                    </div>
                                    <p onInput={identifyPosition33} id="MB7777" contentEditable={contentEditable} suppressContentEditableWarning={true} className="someWording" >{section7777.body}</p>
                                    <div className="encloseMyButton">
                                        <button className="addBenefit" onClick={alterSection}>Change Body</button>
                                    </div>
                                </div>:
                                <Link to="/program#3day" className="enclose14CleanseContents">
                                    <p ref={twiceEncloseDuration1Ref}className="duration" contentEditable={contentEditable} suppressContentEditableWarning={true}>{section777.body}</p>
                                    <p ref={twiceEncloseDurationGroup1Ref} className="someWording" contentEditable={contentEditable} suppressContentEditableWarning={true} >{section7777.body}</p>
                                </Link>
                            }
                                    
                            </div>
                        </div>
                    </div>
                    </Reveal>
                </div>
            </div>
            <div className="wordSection">
                <div className="line"></div>
                <Reveal keyframes={rightAnimation}>
                <div className="encloseWordSection">
                    <p className="heading"></p>
                    <p ref ={wordRef2} id={wordSection2.id} onInput={changeSection2} onBlur={handleBlur} className="wordSectionBody" contentEditable={contentEditable} suppressContentEditableWarning={true}>
                    {wordSection2.body}</p>
               
                </div>
                </Reveal>
            </div>
           
            <div class="myMeals">
                <Reveal keyframes={headingAnimation}>
                    <div>
                        <p contentEditable={contentEditable} suppressContentEditableWarning={true} ref={mealSectionRef} id="MH4" className="heading" onInput={identifyPosition4}>{heading4.body}</p>
                        <div className="encloseMyButton">
                            <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                        </div>
                        </div>
                </Reveal>
                <div className="belowHeading"></div>
                    <Reveal keyframes={bottomAnimation}>
                <p className="body" ref={mealSectionBodyRef} onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true}>{section3.body}</p>
                <div className="encloseMyButton">
                    <button className="addBenefit" onClick={alterSection}>Change Body</button>
                </div>
                <div>
                    <div className="mealPhoto">
                        <img src={j} alt="meal"/>
                        <div className="mealData">
                            <div className="containMealData">
                                <p>Pilau</p>
                                <p>Ingredients and quantities</p>
                                <div>
                                    <ul>
                                        <li>Collards</li>
                                        <li>Beef</li>
                                        <li>Onions</li>
                                        <li>Maize</li>
                                        <li>Beets</li>
                                        <li>Coriander</li>
                                    </ul>
                                    <div className="directions">
                                        <p>Directions</p>
                                        <ul>
                                            <li>Wash the rice</li>
                                            <li>Wash the rice</li>
                                            <li>Wash the rice</li>
                                            <li>Wash the rice</li>
                                            <li>Wash the rice</li>
                                            <li>Ready to serve</li>
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
                </Reveal>
            </div>
            
            <div className="wordSection">
                <div className="line"></div>
                <Reveal keyframes={rightAnimation}>
                <div className="encloseWordSection">
                    <p ref={wordRef3} id={wordSection3.id} onInput={changeSection3} onBlur={handleBlur} className="wordSectionBody" contentEditable={contentEditable} suppressContentEditableWarning={true}>{wordSection3.body} </p>
            
                </div>
                </Reveal>
            
            </div>
            
            <div className="healthyMind">
                <Reveal keyframes={headingAnimation}>
                <div>
                <p ref={mindRef} contentEditable={contentEditable} suppressContentEditableWarning={true} id="MH5" className="heading" onInput={identifyPosition5}>{heading5.body}</p>
                    <div className="encloseMyButton">
                        <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                    </div>
                </div>
               
                </Reveal>
                <div className="belowHeading"></div>
                <Reveal keyframes={bottomAnimation}>
                <div className="contentSection">
                    <div className="contentImage">
                        <img src= {n} alt="Meditation"/>
                    </div>
                    <div className="healthyMindSection">
                        <p ref={mindSubRef} id="MB4" onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true} className="subHeading">{section4.body}</p>
                        <div className="encloseMyButton">
                            <button className="addBenefit" onClick={alterSection}>Change Body</button>
                        </div>
                        <p ref={mindBodyRef} id="MB44" onInput={identifyPosition33} className="subBody" contentEditable={contentEditable} suppressContentEditableWarning={true}> {section44.body} </p>
                        <div className="encloseMyButton">
                            <button className="addBenefit" onClick={alterSection}>Change Body</button>
                        </div>
                        <Link to='/program#meditation_class' className="specialButton">Enrol</Link>
                       
                    </div>
                </div>
                </Reveal>

            </div>
            <div className="wordSection">
                <div className="line"></div>
                <Reveal keyframes={rightAnimation}>
                <div className="encloseWordSection">
                    <p ref={wordRef4} id={wordSection4.id} onInput={changeSection4}  onBlur={handleBlur} className="wordSectionBody" contentEditable={contentEditable} suppressContentEditableWarning={true}>{wordSection4.body}</p>    
                </div>
                </Reveal>
            
            </div>
            <div className="myReviews">
                <div className="encloseHomeReviews">
                
                <div className="encloseReviewTitle">
                    <div style={headingStyle} className="reviewHeader">
                            {editReviewHeader === true ?
                                <div>
                                    <p ref={newReviewRef} id="MH6" contentEditable={contentEditable} suppressContentEditableWarning={true} onInput={identifyPosition6} >#Insert your preferred title#</p>
                                    <div className="encloseMyButton">
                                        <button onClick={discardEditReview}>Discard</button>
                                        <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <Wave ref={reviewRef} id="MH6" text={heading6.body} effect="stretch" effectChange={2.0} />
                                    <button onClick={invokeEditReview}>Modify</button>
                                </div>
                            }
                                
                        
                        
                    </div>
                </div>
                <div className="belowHeading"></div>
                <Reveal keyframes={bottomAnimation}>
                <div className="encloseMyReviews">
                    {rClick > 0 &&
                    <BsArrowLeftShort className="reviewArrow" onClick={addClick}/>
                    }
                    
                        {rClick === 0 && 
                            <div className="homepageReviews">
                                <Reviews/>
                            </div>
                        }
                        {rClick === 1 && 
                            <div className="homepageReviews">
                                <Reviews />
                            </div>
                        }
                        {rClick === 2 && 
                            <div className="homepageReviews">
                                <Reviews />
                            </div>
                        }
                        {rClick === 3 && 
                            <div className="homepageReviews">
                                <Reviews />
                            </div>
                        }
                        {rClick === 4 && 
                            <div className="homepageReviews">
                                <Reviews />
                            </div>
                        }

                    {rClick < 4 &&  <BsArrowRightShort className="reviewArrow" onClick={reduceClick}/>}
                   
                </div>
                </Reveal>
                </div>
            </div>
            
            <div className="weHelp" >
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseHelpSection">
                <div className="leftHelp">
                    <div className="mainHelpLeft">
                    </div>
                    <div className="mainHelpRight">
                            <div className="encloseMainHelpImage">
                                <img src={e} alt="coach"/>
                            </div>
                    </div>
                </div>
            <div className="rightHelp">
                <p ref={rightHelpRef} id="MB5" onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true} className="rightHelpHeading">{section5.body}</p>
                <div className="encloseMyButton">
                    <button className="addBenefit" onClick={alterSection}>Change Body</button>
                </div>
                <div className="belowText"></div>
                <div className="rightHelpBody">
                    <Link to='/calls' className="helpClarityCall" title={helpText}>{section55.body}</Link>
                    <p ref={rightHelpClarityRef} onInput={identifyPosition33} contentEditable={contentEditable} suppressContentEditableWarning={true} id="MB555" className="helpClarityInfo">{section555.body}</p>
                    <div className="encloseMyButton">
                        <button className="addBenefit" onClick={alterSection}>Change Body</button>
                    </div>
                </div>
                
            </div>
           
            </div>
            </Reveal>
            </div>
            <div className="mentor">
                <Reveal keyframes={headingAnimation}>
                        <div>
                        <p ref={mentorRef}  onInput={identifyPosition7} contentEditable={contentEditable} suppressContentEditableWarning={true} id="MH7" className="heading">{heading7.body}</p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                            </div>
                        </div>
                    
                </Reveal>
                <div className="belowHeading"></div>
                <Reveal keyframes={bottomAnimation}>
                {changeCoachSection === true?
                    <div className="thementor">
                        <div className="coachImage first">
                            <img src={o} alt="coachDescription"/>
                        </div>
                        <div  className="coachDescription">
                            <p onInput={identifyPosition33} id="MB6" contentEditable={contentEditable} suppressContentEditableWarning={true} ref={mentorBodyRef}>{section6.body}
                            </p>
                            <div className="encloseMyButton">
                                <button className="addBenefit" onClick={alterSection}>Modify</button>
                            </div>
                        </div>
                        <div className="coachImage last" >
                            <img src={o} alt="coachDescription"/>
                        </div>
                </div>
                    :
                    <div>
                        <Link to='/about#about_nyokabi' className="thementor">
                        <div className="coachImage first">
                            <img src={o} alt="coachDescription"/>
                        </div>
                        <div  className="coachDescription">
                            <p ref={mentorBodyRef}>{section6.body}
                            </p>
                        </div>
                        <div className="coachImage last" >
                            <img src={o} alt="coachDescription"/>
                        </div>
                    </Link>
                    <button onClick={coachSectionModification}>Modify</button>
                    </div>
                }
                
                </Reveal>
              
            </div>
            <div className="wordSection">
                <div className="line"></div>
                <Reveal keyframes={rightAnimation}>
                <div className="encloseWordSection">
                    <p ref={wordRef5} id={wordSection5.id} onInput={changeSection5}  onBlur={handleBlur} className="wordSectionBody">{wordSection5.body}</p>
                    
                </div>
                </Reveal>
            </div>
            <div className="eventSection" style={{backgroundImage: `url("${p}")`}}>
            <Reveal keyframes={bottomAnimation}>  
                <div>
                <p ref={eventsRef} contentEditable={contentEditable} suppressContentEditableWarning={true} id="MH8" onInput={identifyPosition8}>{heading8.body}</p>
                    <div className="encloseMyButton">
                        <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                    </div>
                </div>
                <button className="specialButton" onClick={headToEvents}>View events</button>
            </Reveal>
            </div>
            
            <div className="blogSection">
                <Reveal keyframes={headingAnimation}>
                <div>
                    <p ref={BlogRef} id="MH9" contentEditable={contentEditable} suppressContentEditableWarning={true} className="title heading" onInput={identifyPosition9}>{heading9.body}</p> 
                    <div className="encloseMyButton">
                        <button className="addBenefit" onClick={fillHomeBody}>Change title</button>
                    </div>
                </div>
                </Reveal>
                <div className="belowHeading"></div>
                <Reveal keyframes={bottomAnimation}>
                <div className="encloseEntireBlog">
                    <div className="encloseBlogSection">
                        <div className="blogLeft">
                            <p className="subTitle subheader">Vitamin </p>
                            <img src={q} alt="letter"/>
                        </div>
                        <div className="blogRight">
                            <div className="vitaminBody">
                                <p className="subTitle">What it does to the body</p>
                                <p className="body">Vitamin A is a fat-soluble vitamin that is 
                                naturally present in many foods. Vitamin A is 
                                important for normal vision, the immune system, 
                                reproduction, and growth and development. Vitamin A 
                                also helps your heart, lungs, and other organs work 
                                properly. Carotenoids are pigments that give yellow, orange, and red fruits and vegetables their color. Your body is able to convert some carotenoids into vitamin A.</p>
                            </div>
                            <div className="vitaminFoods">
                                <p className="subTitle">Foods which have vitamin A</p>
                                <ul className="body">
                                    <li>Fish</li>
                                    <li>Organ meats</li>
                                    <li>Green leafy vegetables</li>
                                    <li>Cantaloupe</li>
                                    <li>Dairy products such as milk and cheese</li>
                                    <li>Fortified breakfast cereals</li>
                                    <li>Eggs</li>
                                </ul>
                            
                            </div>
                            <Link to="/article#encloseBackButton" >Read more ....</Link>
                        </div>
                    </div>
                    <div className="sideBar">
                        <Link to="/article#encloseBackButton">
                            <div className="homeArticle">
                                <p>Who needs nitrates?</p>
                            </div>
                        </Link>
                        
                        <Link to="/article#encloseBackButton" >
                            <div className="homeArticle" >
                                <p> Calcium, consectetur adipiscing elit</p>
                            </div>
                        </Link>
                        
                        <Link to="/article#encloseBackButton">
                            <div className="homeArticle noborder">
                                <p>Lorem ipsum dolor sit amet</p>
                            </div>
                        </Link>
                        
                        <Link to="/article#encloseBackButton">
                            <div className="noborder">
                                <p>Lorem ipsum dolor sit amet</p>
                            </div>
                        </Link>
                        
                    </div>
                </div>
                </Reveal>
            </div>
            
            
        </div>
    )
}