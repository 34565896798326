import React from "react";
import "../css/reviews.css";
import Navbar from "./navbar";
import Final from "./final";
import Footer from "./footer";
import {GoPerson} from "react-icons/go";


export default function Reviews(){
    const message = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat";
    
    return(
        <div className="reviews">
        <div className="encloseReviews">
            <div className="encloseReviewInfo">
                <div className="encloseImage">
                    <GoPerson/>
                </div>
                <div className="encloseComment">
                    <div className="triangleLeft"></div>
                    <div className="encloseText">
                        <div className="review">
                            <p>{message}</p>
                        </div>
                        <div className="bottomsection">
                        <div className="reviewer">
                            <p>Johnstone Muthama</p>
                        </div>
                        <div className="datereviewed">
                            <p>23 <sup>rd</sup> October, 2023.</p>
                        </div>
                    </div>
                        
                    </div>
                   
                </div>
                
            </div>
        </div>

        </div>
    )
}