import React from "react";
import "../css/footer.css";
import {BiLogoFacebook} from "react-icons/bi";
import {BiLogoTwitter} from "react-icons/bi";
import {BiLogoInstagramAlt} from "react-icons/bi";
import {BiLogoLinkedin} from "react-icons/bi";
import {BiLogoYoutube} from "react-icons/bi";
import {FaSpotify} from "react-icons/fa";
import logo from "../img/hhh.png";


export default function Footer(){
    return(
        <div className="footer">
            <div className="encloseSecondFooter">
                <div className="leftSide">
                    <img src={logo} alt="Logo"/>
                </div>
                <div className="rightSide">
                    <div className="first">
                        <div className="subscribeSection">
                            <p className="subscribeWording">Subscribe to our newsletter</p>
                            <form>
                                <div className="emailSection">
                                    <input  type="email" placeholder="Email"/>
                                </div>
                                <div className="buttonSection ">
                                <input className="subscribeButton" type="submit" value="Submit"/>
                                </div>
                               
                            </form>
                            
                        </div>
                        <div className="socialSection">
                            <p>Let's connect</p>
                            <div className="socials" id="socials">
                                <BiLogoFacebook/>
                                <BiLogoTwitter/>
                                <BiLogoInstagramAlt/>
                                <BiLogoLinkedin/>
                                <BiLogoYoutube/>
                                <FaSpotify/>
                            
                            </div>
                        </div>
                    </div>
                    <div className="footerEnclosing">
                        <ul className="a">
                            <li>Home</li>
                            <li>About</li>
                            <li>Blog</li>
                            <li>Account</li>
                            <li>Contact</li>
                            <li>Training</li>
                            <li>Clarity call</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
           
           
            
        </div>
    )
}