import React from "react";


export default function CleanseList(props){
    const data = props.props
    return(
        <div>
            {Object.values(data).map((key, index)=>{
                return(
                    <li>
                    {key.task}
                    <div className="changeButtons">
                        <button>Delete</button>
                        <button>Modify</button>
                    </div>
                    
                    </li>
                );
            })}
        </div>
    )
}