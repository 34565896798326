import React, {useState} from "react";
import TopNavbar from "./topnav";
import Dropdown from "./dropdown";
import "../css/navbar.css";

export default function Navbar(){
    const [dropdown, setDropdown] = useState(false);
    
    return(
        <div className="_navbar">
            <div className="navbarContainer">
                <TopNavbar/>
            </div>
            <div className="dropdownContainer" style={{display: dropdown ? 'block' : 'none' }}>
                <Dropdown/>
            </div>
            
            
        </div>
    )
}