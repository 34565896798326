import React, {useEffect, useState} from "react";
import "../css/blog.css";
import Navbar from "./navbar";
import Final from "./final";
import Footer from "./footer";
import {
    FacebookShareButton,
    FacebookIcon,
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TelegramIcon,
    TelegramShareButton,
} from "react-share";
import c from "../img/dback.jpg";
import Enclose from "./enclosure";
import {BsArrowLeft} from "react-icons/bs";
import { useNavigate } from "react-router";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;




export default function Blog(){
    const message = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc,"
    const [login, setLogin] = useState(false);
    const [register, setReg] = useState(false);
    const shareUrl = window.location.href;
    const title = "Vitamin A";
    const [back, setBack] = useState(false);
    const [currentTitle, setCurrentTitle] = useState();
    const [currentID, setCurrentID] = useState();
    const [currentScript, setCurrentScript] = useState();
    const navigate = useNavigate();

    function goBack(){
        setBack(true);
    }
    function setCurrentBlog(){
        void(0);
    }
    function deleteBlog(){
        void(0)
    }

    useEffect(()=>{
        if(back === true){
            navigate('/blog');
        }
    }, [back]);
    useEffect(()=>{
        (login === true) ? navigate('/login') : void(0);
    }, [login]);
    useEffect(()=>{
        (register === true) ? navigate('/register') : void(0);
    }, [register]);
    function showSignUp(){
        setReg(true);
    }
    function showLogin(){
        setLogin(true);
    }
    
    
    return(
        <div className="blog">
            <Navbar/>
            <Reveal keyframes={bottomAnimation}>
            <div className="encloseBlog">
                <div className="firstSection" id="encloseBackButton" >
                    <BsArrowLeft/>
                    <button onClick={goBack} className="blogHeading">back</button>         
                </div>
                <hr/>

                <div className="secondSection">
                    <div className="blogBody">
                        <div className="encloseBody">
                            <div className="encloseTitle">
                                <p>{title}</p>
                            </div>
                            <div className="encloseMessage">
                                <p>{message}</p>
                            </div>
                        
                        </div>
                        
                    </div>
                    <div className="blogContents">
                        <div className="encloseBlogContents">
                            <fieldset>
                                <legend>Hello Reader</legend>
                                <ul>
                                    <li>Article 1</li>
                                    <li>Article 2</li>
                                    <li>Article 3</li>
                                </ul>
                            </fieldset>
                        </div>
                        <div className="encloseBlogAdvertisements"> 
                            <p>Advertisements appear here</p>
                        </div>
                   
                    </div>
                </div>
                <div className="fillSpace2"></div>
                <div className="thirdSection">
                    <form>
                        <div className="formInput">
                            <input type="text"/>
                        </div>
                        <div className="formInputButton">
                            <input type="submit" value="Comment"/>
                        </div>
                    </form>
                    <div className="existingComments">
                        <p className="responses">All comments</p>
                        <div className="individualComment">
                            <p className="user"><em>User ~ johndoe@gmail.com</em></p>
                            <p className="comment">Thanks for sharing</p>
                            <span className="reactions">
                                <p>Like</p>
                                <p>Comment</p>
                            </span>
                        </div>
                        <div className="individualComment">
                            <p className="user"><em>User ~ johndoe@gmail.com</em></p>
                            <p className="comment">Thanks for sharing</p>
                            <span className="reactions">
                                <p>Like</p>
                                <p>Comment</p>
                            </span>
                        </div>
                        <div className="individualComment">
                            <p className="user"><em>User ~ johndoe@gmail.com</em></p>
                            <p className="comment">Thanks for sharing</p>
                            <span className="reactions">
                                <p>Like</p>
                                <p>Comment</p>
                            </span>
                        </div>
                    </div>
                    <div className="share">
                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <TwitterIcon
                            size={32}
                            round />
                        </TwitterShareButton>
                        <FacebookShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <FacebookIcon
                            size={32}
                            round />
                        </FacebookShareButton>
                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <WhatsappIcon
                            size={32}
                            round />
                        </WhatsappShareButton>
                        <LinkedinShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <LinkedinIcon
                            size={32}
                            round />
                        </LinkedinShareButton>
                        <PinterestShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <PinterestIcon
                            size={32}
                            round />
                        </PinterestShareButton>
                        <EmailShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <EmailIcon
                            size={32}
                            round />
                        </EmailShareButton>
                        <RedditShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <RedditIcon
                            size={32}
                            round />
                        </RedditShareButton>
                        <TelegramShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <TelegramIcon
                            size={32}
                            round />
                        </TelegramShareButton>
                  
                    </div>
                
                </div>
            </div>
            </Reveal>
            <Footer/>
            <Final/>
        </div>
    )
}