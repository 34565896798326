import { TypeAnimation } from 'react-type-animation';


export default function TextAnime(props){
    return (
        <TypeAnimation
        sequence={
          [
            props.props[0], 1000,
            props.props[1], 1000,
            props.props[2], 1000,
            props.props[3], 1000,
            props.props[4], 1000,
          ]
          }
          wrapper="span"
          speed={50}
          style={{ fontSize: '1.5em', display: 'inline-block', color : 'rgba(37, 35, 36, 1)', fontFamily: 'Noto Sans', textTransform: 'capitalize'}}
          repeat={Infinity}
        />
      );
}