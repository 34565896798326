import React, {useState, useEffect} from "react";
import "../css/firsthome2.css";
import bg from "../img/3.png";
import sm from "../img/jj.jpg";
import {FaLongArrowAltRight} from "react-icons/fa";
import { Reveal, Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Slide, Zoom} from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { useNavigate } from "react-router";
import Program from "./program";
import { populateHeading, getHeading, populateSection } from "./homepage";
import {saveImage, saveImageToDB} from './topnav';

const headingAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;


export default function FirstHomeSection(){
  const navigate = useNavigate();
  const [user, setUser] = useState("Muma");
  const [contentEditable, setContentEditable] = useState(true)
  const [heading, setHeading] = useState();
  const [section, setSection] = useState();
  const [imageID, setImageID] = useState()
  const [image, setImage] = useState({preview:'', data:''});
  const [editable, setEditable] = useState(true);
  const inputRef = React.useRef(null);
  const sectionRef = React.useRef(null);
  const submitRef = React.useRef(null);
  const imageRef = React.useRef(null);
  let p, response, g;
  const showPrograms = (event) => {
    event.preventDefault();
    navigate('/program');
  }
  // useEffect(()=>{
  //   populateHeading('/insertText', "POST", "Embrace Total Wellness", "navItem")
  //   populateSection('/', 'POST', 'BSO', 'Embrace total wellness', 'Your transformation journey begins with one step. Guess what\
  //     that step is? Enroling to the tested and proven program. I can help you feel alive.\
  //     Let me walk with you as you get your groove back.', 'First')
  // }, []);
  async function getHeading(){
    response = await fetch("/getTitle",{
      method: 'GET',
      mode: "cors",
      redirect:"error",
      headers:{
          "Content-Type":"application/json"
      },
  }
  )
  p = await response.json();
  if (p.status === 200){
     setHeading(p.message);
  }
  else{
      alert(p.message);
  }

  }
  function getSection(){
    //getitfrom context
  }
  async function getImage(){
    response = await fetch('/getLogo', {
      method: 'GET',
      mode: "cors",
      redirect:"error",
      headers:{
          "Content-Type":"application/json"
      },
  })
  p = await response.json();
  if (p.status === 200){
      g = 'https://wellnesswithnyokabi.com/images/' + p.message.name;
      setImageID(p.message.id)
      setImage(g);
  }
  else{
      alert(p.message);
  }
  }
  function changeHeading(e){
    e.preventDefault();
    setHeading(inputRef.current?.innerText);
    //populateHeading('/alterTitle', "PUT", "HSO", heading)
  }
  function changeBody(e){
    e.preventDefault();
    setHeading(sectionRef.current?.innerText);
    //populateSection('/alterSection', "PUT", "HSO", heading)

  }
  function changeImage(e){
    e.preventDefault();
    const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
    }
    setImage(img);
    submitRef.current?.click();
    
  }
  async function handleSubmit(e){
    e.preventDefault();
    let formData = new FormData()
    formData.append('file', image.data)
    response = await fetch('/saveImage', {
        method: 'POST',
        body: formData,
    })
    p = await response.json();
    if (p.status === 200){
        saveImageToDB(p.message, imageID, "logo");
    }
    else{
        alert(p.message);
    }

  }
    return(
        
        <div className="firstHomeSection" >
            <div className="rightSection">
              <Reveal keyframes={headingAnimation}>
                <div className="encloseHeading">
                  <p ref={inputRef} className="heading" contentEditable={contentEditable}>Embrace total wellness</p>
                  <div className="encloseMyButton">
                    <button className="addBenefit" onClick={changeHeading}>Change Heading</button>
                  </div>
                </div>              
              </Reveal>
              <Fade>
                <div className="encloseBody">
                  <p ref={sectionRef} className="body largeBody" contentEditable={editable} >Your transformation journey begins with one step. Guess what
                  that step is? Enroling to the tested and proven program. I can help you feel alive. 
                  Let me walk with you as you get your groove back.</p>
                  <div className="encloseMyButton">
                    <button className="addBenefit" onClick={changeBody} >Modify body</button>
                  </div>
                </div> 
              </Fade>
            <div>
            <Reveal keyframes={bottomAnimation}>
                <button  className="wholeness specialButton" onClick={showPrograms}>Ready to regain wholeness? Let's go &rarr;</button>
                <button  className="smWholeness" onClick={showPrograms}>Regain wholeness </button>
            </Reveal>
            </div>
            
           
            </div> 
            <div className="leftSection">
                <img src={image.preview !== "" ?image.preview : bg } alt="mentorphoto"/>
                <form className="encloseMyButton" onSubmit={handleSubmit} style={{display:"none"}}>
                  <input ref={imageRef} type="file" onChange={changeImage} accept="png;base64,jpeg;base64,jpg;base64"/>
                  <button ref={submitRef} type='submit'>Submit</button>
                </form>
            </div>
            
           
        </div>
    )
}