import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Final from "./final";
import "../css/coaching.css";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const bottomAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export default function Coaching(){
    return(
        <div className="encloseCoaching">
            <Navbar/>
            <Reveal keyframes={bottomAnimation}>
            <div className="coaching">
                <p><em>Something's cooking... Coming soon</em></p>
            </div>
            </Reveal>
            <Footer/>
            <Final/>
        </div>
    )
}