import React from "react";
import Navbar from "./navbar";
import FirstHomeSection from "./firsthome";
import WordSection from "./beforeCleanse";
import Cleanse from "./cleanse";
import Meals from "./meals";
import Footer from "./footer";
import Final from "./final";
import changeDialog from "./changeDialog";
//import { ActivatePage } from "./activatePage";
//import ChangeDialog from "./changeDialog";
let response, p, id;  
//populate heading, alterHeading
export async function populateHeading(route, method, name){
    response = await fetch(route,{
        method: method,
        mode: "cors",
        redirect:"error",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify({
            items: name
        })
    })
    p = await response.json();
    if (p.status === 200){
        alert(p.message);
    }
    else{
        alert(p.message)
    }
}
//get heading, section
export async function getHeading(route){
    response = await fetch(route,{
        method: 'GET',
        mode: "cors",
        redirect:"error",
        headers:{
            "Content-Type":"application/json"
        },
    })
    p = await response.json();
    if (p.status === 200){
        let myTextArray = [];
        Object.keys(p.message).map((key)=>{
            myTextArray.push({'id': p.message[key].id, 'body':p.message[key].body})
            
        })
        return myTextArray;
    }
    else{
        alert(p.message)
    }
}
//populate and alterSection
export async function populateSection(route, method, item){
    response = await fetch(route,{
        method:  method,
        mode: "cors",
        redirect:"error",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify({
            "item":item
        }) 
        })
        p = await response.json();
        if (p.status === 200){
            alert(p.message);
        }
        else{
            alert(p.message)
        }

}
//populate and alter image
export async function populateImage(method, route, id, image){
    response = await fetch(route,{
        method: `${method}`,
        mode: "cors",
        redirect:"error",
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify({
            "id":id,
            "image":image
        })
    })
    p = await response.json();
    if (p.status === 200){
        alert(p.message);
    }
    else{
        alert(p.message)
    }

}


export default function Homepage(){ 
    return(
        <div className="homepage">
            <Navbar/>
            <FirstHomeSection />
            <WordSection/>
            <Cleanse/>
            <Meals/>
            <Footer/>
            <Final/>
        </div>
    )
}